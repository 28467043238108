<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ activeUserInfo.fullName }}</p>
    </div>
    <vs-dropdown class="cursor-pointer">
      <div class="con-img ml-3">
        <img key="onlineImg" :src="profileImage" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block"/>
      </div>
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import { clearLocalStorage } from "../../../../helper/localStorage.js";

export default {
  data() {
    return {
      profileImage: require("@/assets/images/profile/default.png")
    };
  },

  computed: {
    activeUserInfo() {
      return localStorage.getItem("customer") ? JSON.parse(localStorage.getItem("customer")) : "";
    }
  },

  methods: {
    logout() {
      if (localStorage.getItem("accessToken")) {
        clearLocalStorage();
        this.$router
          .push({ name: "customer-login", params: { id: this.$route.params.merchantId } })
          .catch(() => {});
      }
    }
  }
};
</script>
