<template>
  <footer class="the-footer flex-wrap justify-between ">
    <div
      v-if="merchant && Object.keys(merchant).length && merchant.companyHeader"
      class="lg:px-10 md:px-2 px-0 w-full"
    >
    <vs-row>
        <span class="text-lg font-medium" v-if="merchant.companyHeader.companyName">
        {{ merchant.companyHeader.companyName }}
      </span>
      </vs-row>

      <vs-row class="flex justify-between items-center for-mobile-view">
          <span class="flex items-center footer-items" v-if="merchant.companyHeader.abn">
            <span class="text-base font-medium ">ABN:&nbsp;</span> 
            {{ merchant.companyHeader.abn }}
          </span>
          <span class="flex items-center footer-dots"></span>
        <span class="flex items-center footer-items"
          v-if="merchant.companyHeader.address"
        >
          <vs-icon icon="place"/>
           <span class="ml-2">{{ merchant.companyHeader.address }}</span>
        </span>
        <span class="flex items-center footer-dots"></span>
         <span class="flex items-center footer-items" v-if="merchant.companyHeader.phone">
          <vs-icon icon="phone"/>
          <span class="ml-2">{{ merchant.companyHeader.phone }}</span>
        </span>
        <span class="flex items-center footer-dots"></span>
         <span class="flex items-center footer-items"
          v-if="merchant.companyHeader.website"
        >
          <vs-icon icon="language" />
          <span class="ml-2">
            {{ merchant.companyHeader.website }}
          </span>
        </span>
        <span class="flex items-center footer-dots"></span>
         <span class="flex items-center footer-items"
          v-if="merchant.companyHeader.companyEmail"
        >
          <vs-icon icon="email" />
          <span class="ml-2">{{ merchant.companyHeader.companyEmail }}</span>
        </span>
      </vs-row>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      test: "123"
    };
  },
  props: {
    merchant: { type: Object }
  }
};
</script>